.container_params {
    padding-top: 20px;
    padding-bottom: 50px;
}

.row_params {
    padding-left: 10px;
}

.img_container {
    height: 150px;
    width: auto;
}

.img_params {
    height: 150px;
    width: auto;
}

.name_container {
    height: 150px;
    width: auto;
    display: table;
    float:  left;
    margin-left: 20px;
}

.name_params {
    display: table-cell;
    vertical-align: middle;
    font-family: 'Roboto', "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 25px;
}
