.title_container {
  text-align: center;
  font-size: 35px;
  font-family: "Times New Roman", Times, serif;
  margin-bottom: 20px;
}

.img_container_params {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 25px;
}

.img_params {
  width: 100%;
  max-width: 460px;
  height: auto;
}

.hubb_color {
  background-color: #358fcb;
}

.hubb_footer {
  background-color: #358fcb;
  text-align: center;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;
  padding-bottom: 25px;
}

.quespon_color {
  background-color: #e35a28;
}

.quespon_footer {
  background-color: #e35a28;
  text-align: center;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;
  padding-bottom: 25px;
}
