.main_container {
  padding-top: 180px;
  padding-bottom: 180px;
}

.title_container {
  text-align: center;
  font-size: 35px;
  font-family: "Times New Roman", Times, serif;
  padding-bottom: 16px;
}

.title_img_container {
  text-align: center;
  padding-bottom: 40px;
}


.img_container_params {
  text-align: center;
}

.img_icon_params {
  height: 40px;
  width: auto;
  margin-right: 24px;
  margin-left: 24px;
}

.img_myself_icon_params {
}

.name_params {
  display: table-cell;
  vertical-align: middle;
  font-family: 'Roboto', "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 25px;
}

@media screen and (max-width: 480px) {
  .img_icon_params {
    margin-right: 15px;
    margin-left: 15px;
  }
}

.fotter_row_params {
  margin-top: 120px;
  margin-bottom: 10px;
  background-color: #585858;
}

.fotter_title_params {
  margin-left: 10px;
}
