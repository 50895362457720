.col {
    text-align: center;
}

.title {
    color: #202223;
    font-size: 35px;
    font-family: "Times New Roman", Times, serif;
}

.title_detail {
    color: #202223;
    font-weight: bold;
    font-size: 20px;
    font-family: 'Roboto', "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.detail {
    color: #202223;
    font-size: 18px;
    font-family: 'Roboto', "Helvetica Neue",Helvetica,Arial,sans-serif;
}
